import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { Subscription } from 'rxjs';
import { Category } from 'src/@omnial/_models/catalog/category.model';
import { Artist } from 'src/app/_models/artist.model';
import { ArtistService } from 'src/app/_services/artist.service';

@Component({
    selector: 'app-artists',
    templateUrl: './artists.component.html',
    styleUrls: ['./artists.component.scss'],
    standalone: false
})
export class ArtistsComponent implements OnDestroy {
  @Input() currentCategory: Category;
  public artists: Artist[] = [];
  public subscriptions: Subscription[] = [];

  constructor(public settings: AppSettings,
    public artistService: ArtistService) {
    this.subscriptions.push(this.artistService.artists.subscribe(
      res => {
        if (res) {
          this.artists = res as Artist[];
        } else {
          this.artistService.load();
        }
      }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }
}
